<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">BRANCH INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="branch_code"
                    label="Branch Code"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="addresss"
                    label="Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    dense
                    outlined
                    :rules="rules.email_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="contact_no"
                    label="Contact #"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-checkbox v-model="is_franchise" label="Is Franchise/Outlet" hide-details
                          class="me-3 mt-1"></v-checkbox>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="id===0">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
              <v-btn color="primary" class="me-3 mt-4" @click="update_info" v-if="!saving"> Update
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Branch</th>
              <th class="text-center text-uppercase">Address</th>
              <th class="text-center text-uppercase">Email</th>
              <th class="text-center text-uppercase">Is Franchise</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in initialize_datas" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.branch_code }}
              </td>
              <td class="text-center">
                {{ item.address}}
              </td>
              <td class="text-center">
                {{ item.email}}
              </td>
              <td class="text-center">
                {{ item.is_franchise?'Franchise':'GL'}}
              </td>
              <td class="text-center">
                {{ item.is_active?'ACTIVE':'DEACTIVATED'}}
              </td>
              <td class="text-center">
                <div>
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="success"
                    @click="approved(initialize_datas[initialize_datas.map(function(x) {return x.id; }).indexOf(item.id)])"
                  >
                    {{icons.mdiPencilBoxOutline}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
                <div v-if="item.is_active">
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="error"
                    @click="update_status({
                    id:item.id,
                    status:false
                    })"
                  >
                    {{icons.mdiBellCancel}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
                <div v-else>
                  <v-icon
                    v-if="!is_updating"
                    class="mr-2"
                    color="warning"
                    @click="update_status({
                    id:item.id,
                    status:true
                    })"
                  >
                    {{icons.mdiBellCheck}}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate
                                       v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline,
    mdiBellCancel,
    mdiBellCheck,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      branch_info: '',
      branch_items: [],

      saving: false,
      alert: false,
      alert_message: '',

      b_id: 0,
      id: 0,
      contact_no: '',
      email: '',
      branch_code: '',
      addresss: '',
      is_franchise: false,

      initialize_datas: [],
      is_updating: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
          mdiBellCancel,
          mdiBellCheck,
        },
      }
    },
    mounted() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['get_branches', 'register_branches', 'update_branches', 'update_branches_status']),
      initialize_data() {
        this.get_branches()
          .then(response => {
            this.initialize_datas = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },

      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_code', this.branch_code.toUpperCase())
          data.append('address', this.addresss.toUpperCase())
          data.append('contact_no', this.contact_no)
          data.append('email', this.email)
          data.append('is_franchise', this.is_franchise)
          this.register_branches(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      update_info() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.id)
          data.append('branch_code', this.branch_code.toUpperCase())
          data.append('address', this.addresss.toUpperCase())
          data.append('contact_no', this.contact_no)
          data.append('email', this.email)
          data.append('is_franchise', this.is_franchise)
          this.update_branches(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      approved(data) {
        this.id = data.id
        this.contact_no = data.contact_no
        this.email = data.email
        this.branch_code = data.branch_code
        this.is_franchise = data.is_franchise === 1 ? true : false
        this.addresss = data.address
      },
      update_status(datum) {
        const data = new FormData()
        data.append('id', datum.id)
        data.append('status', datum.status)
        this.update_branches_status(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            } else {
              this.reset()
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
            this.alert = true
            this.alert_message = error.response.data.message
            this.saving = false
          })
      },
    },
  }
</script>
